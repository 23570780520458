import { Typography } from "@mui/material";

const AccountRegistration = () => {
  return (
    <div id="account-registration">
      <Typography variant="h4" component="h2" gutterBottom>
        4.1 AccountRegistration
      </Typography>
    </div>
  );
};

export default AccountRegistration;
