import { Typography } from "@mui/material";

const AppendixASymbolGlossary = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        Appendix A - Symbol Glossary
      </Typography>
    </>
  );
};

export default AppendixASymbolGlossary;
