import { Typography } from "@mui/material";

const ViewingResults = () => {
  return (
    <div id="viewing-results">
      <Typography variant="h4" component="h2" gutterBottom>
        4.5 Viewing the Results
      </Typography>
    </div>
  );
};

export default ViewingResults;
