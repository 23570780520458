import { Typography } from "@mui/material";

const SetUpWiFi = () => {
  return (
    <div id="set-up-wifi">
      <Typography variant="h4" component="h2" gutterBottom>
        5.1 Instructions to Set Up Wi-Fi
      </Typography>
    </div>
  );
};

export default SetUpWiFi;
