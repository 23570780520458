import { Typography } from "@mui/material";

const PortalGuide = () => {
  return (
    <div id="portal-guide">
      <Typography variant="h4" component="h2" gutterBottom>
        3.4 PortalGuide
      </Typography>
    </div>
  );
};

export default PortalGuide;
