import { Typography } from "@mui/material";

const Features = () => {
  return (
    <div id="features">
      <Typography variant="h4" component="h2" gutterBottom>
        3.1 Features
      </Typography>
    </div>
  );
};

export default Features;
