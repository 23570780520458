import { Typography } from "@mui/material";

const HeadMountedDeviceGuide = () => {
  return (
    <div id="head-mounted-device-guide ">
      <Typography variant="h4" component="h2" gutterBottom>
        3.3 HeadMountedDeviceGuide
      </Typography>
    </div>
  );
};

export default HeadMountedDeviceGuide;
