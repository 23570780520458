import { Typography } from "@mui/material";

const Usage = () => {
  return (
    <div id="usage">
      <Typography variant="h4" component="h2" gutterBottom>
        1.2 Usage
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Usage
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Usage
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Usage
      </Typography>
    </div>
  );
};

export default Usage;
