import { Typography } from "@mui/material";

const AppendixCPortalIconGlossary = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        Appendix C - Portal Icon Glossary
      </Typography>
    </>
  );
};

export default AppendixCPortalIconGlossary;
