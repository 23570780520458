import { Typography } from "@mui/material";
import AppendixVisualField from "./AppendixVisualField";

const AppendixBPatientView = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        Appendix B - Patient View
      </Typography>
      <AppendixVisualField />
    </>
  );
};

export default AppendixBPatientView;
