import { Typography } from "@mui/material";

const ContactUs = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        9. Contact Us
      </Typography>
    </>
  );
};

export default ContactUs;
