import { Typography } from "@mui/material";

const CreatingExam = () => {
  return (
    <div id="creating-exam">
      <Typography variant="h4" component="h2" gutterBottom>
        4.3 Creating an Exam
      </Typography>
    </div>
  );
};

export default CreatingExam;
