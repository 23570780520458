import { Typography } from "@mui/material";
import IntendedUse from "./IntendedUse";
import Usage from "./Usage";
import Results from "./Results";

const SystemDescription = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        1. SystemDescription
      </Typography>
      <IntendedUse />
      <Usage />
      <Results />
    </>
  );
};

export default SystemDescription;
