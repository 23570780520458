import { Typography } from "@mui/material";

const SoftwareUpdatesSpecifications = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        2. Software Updates & Specifications
      </Typography>
    </>
  );
};

export default SoftwareUpdatesSpecifications;
