import React from "react";
import { Container, Grid } from "@mui/material";
import Sidebar from "../../components/UI/Sidebar";
import { Outlet } from "react-router-dom";
import AlgoliaSearch from "../../components/UI/AlgoliaSearch";

const OperationalManual = () => {
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            padding: "0",
            margin: "0",
          }}
        >
          {/* Left side content */}
          <Grid item xs={12} sm={6} md={8} pr={10}>
            <Outlet />
          </Grid>
          {/* Right side content */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              bgcolor: "#F7F7F7",
            }}
          >
            <AlgoliaSearch />
            <Sidebar />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OperationalManual;
