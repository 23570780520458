import algoliasearch from "algoliasearch";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  connectSearchBox,
  connectStateResults,
  Hits,
  InstantSearch,
} from "react-instantsearch-dom";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const AlgoliaSearch = () => {
  const navigate = useNavigate();

  const [isInputFocused, setIsInputFocused] = useState(false);
  const searchContainerRef = useRef(null);

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) => {
      const hasResults = searchResults && searchResults.nbHits !== 0;

      return (
        <div>
          {hasResults ? (
            children
          ) : (
            <div className={styles.noResultsMessage}>
              No results found for "{searchState.query}"
            </div>
          )}
        </div>
      );
    }
  );

  const handleClick = useCallback(
    (hit) => {
      setIsInputFocused(false);
      const subPath =
        typeof hit?.path === "string"
          ? hit.path.trim().split("/").filter(Boolean).pop()
          : "";
      if (window.location.pathname.includes(hit?.path)) {
        if (hit?.type === "main-link") {
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          const element = document.getElementById(subPath);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
      } else {
        navigate(hit.path);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsInputFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Hit = ({ hit }) => (
    <div
      className={styles.hitItem}
      onClick={() => {
        handleClick(hit);
      }}
      style={{ cursor: "pointer" }}
      data-testid="hit"
    >
      <h4>{hit.title}</h4>
      <p>{hit.description}</p>
    </div>
  );

  const SearchBox = connectSearchBox(({ currentRefinement, refine }) => (
    <input
      className="shadow-md rounded-lg bg-20233810 text-gray-700 px-3 py-2 w-full outline-none border border-gray-300 focus:border-blue-400"
      type="text"
      onFocus={() => setIsInputFocused(true)}
      onChange={(e) => refine(e.target.value)}
      placeholder="Search the docs..."
      value={currentRefinement}
    />
  ));

  return (
    <div ref={searchContainerRef} className={styles.searchContainer}>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.REACT_APP_ALGOLIA_INDEX_NAME}
      >
        <SearchBox />
        <div
          className={`${styles.searchResultsContainer} ${isInputFocused ? styles.visible : styles.hidden}`}
        >
          <Results>
            <Hits hitComponent={Hit} />
          </Results>
        </div>
      </InstantSearch>
    </div>
  );
};

export default AlgoliaSearch;
