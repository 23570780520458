import { Typography } from "@mui/material";
import SetUpWiFi from "./SetUpWiFi";
import VRDeviceCare from "./VRDeviceCare";

const VirtualRealityDevice = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        5. Virtual Reality (VR) Device
      </Typography>
      <SetUpWiFi />
      <VRDeviceCare />
    </>
  );
};

export default VirtualRealityDevice;
