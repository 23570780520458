import { Typography } from "@mui/material";

const TestPatterns = () => {
  return (
    <div id="test-patterns">
      <Typography variant="h4" component="h2" gutterBottom>
        3.2 Test Patterns
      </Typography>
    </div>
  );
};

export default TestPatterns;
