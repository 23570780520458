import { Typography } from "@mui/material";

const FAQ = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        6. FAQ
      </Typography>
    </>
  );
};

export default FAQ;
