import { Typography } from "@mui/material";
import AccountRegistration from "./AccountRegistration";
import TurningOnVRDevice from "./TurningOnVRDevice";
import CreatingExam from "./CreatingExam";
import LaunchingExam from "./LaunchingExam";
import ViewingResults from "./ViewingResults";

const InstructionsForUse = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        4. Instructions for Use
      </Typography>
      <AccountRegistration />
      <TurningOnVRDevice />
      <CreatingExam />
      <LaunchingExam />
      <ViewingResults />
    </>
  );
};

export default InstructionsForUse;
