import { Typography } from "@mui/material";

const WarningsPrecautions = () => {
  return (
    <div id="warnings-and-precautions">
      <Typography variant="h4" component="h2" gutterBottom>
        Warnings and Precautions
      </Typography>
    </div>
  );
};

export default WarningsPrecautions;
