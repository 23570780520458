import { Typography } from "@mui/material";

const AppendixVisualField = () => {
  return (
    <div id="appendix-visual-field">
      <Typography variant="h4" component="h2" gutterBottom>
        Appendix VisualField
      </Typography>
    </div>
  );
};

export default AppendixVisualField;
