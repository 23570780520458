import { Typography } from "@mui/material";
import Features from "./Features";
import TestPatterns from "./TestPatterns";
import HeadMountedDeviceGuide from "./HeadMountedDeviceGuide";
import PortalGuide from "./PortalGuide";

const GettingStarted = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        3. Getting Started
      </Typography>
      <Features />
      <TestPatterns />
      <HeadMountedDeviceGuide />
      <PortalGuide />
    </>
  );
};

export default GettingStarted;
