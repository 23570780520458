import { Typography } from "@mui/material";

const IntendedUse = () => {
  return (
    <div id="intended-use">
      <Typography variant="h4" component="h2" gutterBottom>
        1.1 IntendedUse
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        IntendedUse
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        IntendedUse
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        IntendedUse
      </Typography>
    </div>
  );
};

export default IntendedUse;
