import { Typography } from "@mui/material";

const Browser = () => {
  return (
    <>
      <Typography variant="h3" component="h1" gutterBottom>
        7. Browser
      </Typography>
    </>
  );
};

export default Browser;
