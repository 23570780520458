import { List, ListItem, ListItemText } from "@mui/material";
import { Fragment, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // const [lastClicked, setLastClicked] = useState(location.pathname);

  const handleClick = (main, subPath) => {
    const mainPath = `/operating-manual/${main}`;
    const fullPath = subPath ? `${mainPath}/${subPath}` : mainPath;
    if (window.location.pathname === mainPath) {
      if (!subPath) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        const element = document.getElementById(subPath);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      console.log(fullPath);
      navigate(fullPath);

      if (!subPath) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    // setLastClicked(subPath ?? fullPath);
  };

  const isActiveLink = useCallback(
    (path) => location.pathname === path,
    [location.pathname]
  );

  useEffect(() => {
    const fullPath = location.pathname;
    const lastPart = fullPath.split("/").pop();
    if (lastPart) {
      const element = document.getElementById(lastPart);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const sections = [
    {
      title: "Important information - Read Before Use",
      path: "important-information",
      children: [
        { title: "Warnings and Precautions", path: "warnings-and-precautions" },
      ],
    },
    {
      title: "1. System Description",
      path: "system-description",
      children: [
        {
          title: "1.1 Intended use & Indications for Use",
          path: "intended-use",
        },
        { title: "1.2 Usage", path: "usage" },
        { title: "1.3 Results", path: "results" },
      ],
    },
    {
      title: "2. Software Updates & Specifications",
      path: "software-updates",
    },
    {
      title: "3. Getting Started",
      path: "getting-started",
      children: [
        { title: "3.1 Features", path: "features" },
        { title: "3.2 Test Patterns", path: "test-patterns" },
        {
          title: "3.3 Head Mounted Device Guide",
          path: "head-mounted-device-guide",
        },
        { title: "3.4 Portal Guide", path: "portal-guide" },
      ],
    },
    {
      title: "4. Instructions for Use",
      path: "instructions-for-use",
      children: [
        {
          title: "4.1 Account Registration & Initial Set Up",
          path: "account-registration",
        },
        { title: "4.2 Turning on the VR Device", path: "turning-on-vr-device" },
        { title: "4.3 Creating an Exam", path: "creating-exam" },
        { title: "4.4 Launching an Exam", path: "launching-exam" },
        { title: "4.5 Viewing the Results", path: "viewing-results" },
      ],
    },
    {
      title: "5. Virtual Reality (VR) Device",
      path: "virtual-reality-device",
      children: [
        { title: "5.1 Instructions to Set Up Wi-Fi", path: "set-up-wifi" },
        { title: "5.2 VR Device Care", path: "vr-device-care" },
      ],
    },
    { title: "6. FAQ", path: "faq" },
    { title: "7. Browser", path: "browser" },
    { title: "8. Security", path: "security" },
    { title: "9. Contact Us", path: "contact-us" },
    {
      title: "Appendix A - Symbol Glossary",
      path: "appendix-a-symbol-glossary",
    },
    {
      title: "Appendix B - Patient View",
      path: "appendix-b-patient-view",
      children: [{ title: "Visual Field", path: "appendix-visual-field" }],
    },
    {
      title: "Appendix C - Portal Icon Glossary",
      path: "appendix-c-portal-icon-glossary",
    },
  ];

  return (
    <List>
      {sections.map((section) => (
        <Fragment key={section.title}>
          <ListItem
            component={Link}
            to={`/operating-manual/${section.path}`}
            onClick={() => handleClick(`${section.path}`)}
            sx={{
              textDecoration: isActiveLink(`/operating-manual/${section.path}`)
                ? "underline"
                : "none",
              color: isActiveLink(`/operating-manual/${section.path}`)
                ? "primary.main"
                : "text.primary",
              "&:hover": {
                textDecoration: "underline",
                color: "primary.main",
              },
            }}
          >
            <ListItemText primary={section.title} />
          </ListItem>
          {section.children &&
            section.children.map((child) => (
              <ListItem
                key={child.title}
                component={Link}
                to={`/operating-manual/${section.path}/${child.path}`}
                onClick={() => handleClick(section.path, child.path)}
                sx={{
                  textDecoration: isActiveLink(
                    `/operating-manual/${section.path}/${child.path}`
                  )
                    ? "underline"
                    : "none",
                  color: isActiveLink(
                    `/operating-manual/${section.path}/${child.path}`
                  )
                    ? "primary.main"
                    : "text.primary",
                  pl: 4,
                  "&:hover": {
                    textDecoration: "underline",
                    color: "primary.main",
                  },
                }}
              >
                <ListItemText primary={child.title} />
              </ListItem>
            ))}
        </Fragment>
      ))}
    </List>
  );
};

export default Sidebar;
