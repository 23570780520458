import { Typography } from "@mui/material";

const Results = () => {
  return (
    <div id="results">
      <Typography variant="h4" component="h2" gutterBottom>
        1.3 Results
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Results
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Results
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Results
      </Typography>
    </div>
  );
};

export default Results;
