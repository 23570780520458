import { Typography } from "@mui/material";

const LaunchingExam = () => {
  return (
    <div id="launching-exam">
      <Typography variant="h4" component="h2" gutterBottom>
        4.4 Launching an Exam
      </Typography>
    </div>
  );
};

export default LaunchingExam;
